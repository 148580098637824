<template>
  <BarGraphic :options="chartOptions" :data="chartData" :id="id" />
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "BarChartProducts",
  components: {
    BarGraphic: () => import("@/components/ui/graphs/BarGraphic"),
  },
  computed: {
    chartData() {
      return {
        labels: this.$store.state.stats.lists.salesByProduct.map(
          (p) => p.inventory.product_name
        ),
        datasets: [
          {
            backgroundColor: this.$store.state.stats.lists.salesByProduct.map(
              (p) => this.randomColor()()
            ),
            data: this.$store.state.stats.lists.salesByProduct.map(
              (p) => p.cantidad
            ),
          },
        ],
      };
    },
  },
  mounted() {},
  methods: {
    ...mapGetters("app", ["randomColor"]),
  },
  data() {
    return {
      id: "productsGraph",
      chartOptions: {
        responsive: true,
        indexAxis: "y",
        maxBarThickness: 20,
        minBarThickness: 10,

        plugins: {
          legend: {
            display: false,
          },
        },
      },
    };
  },
};
</script>
<style></style>
